/*eslint-disable*/
import { call, put, takeLatest } from "redux-saga/effects";
import { functions } from "../firebase/firebase";

//Actions
import * as ACTIONS from "../actions";

import { isEmpty } from "lodash";

const fetchRoute = functions.httpsCallable("fetchEndpoint");

//Saga Handler
function* handleData(action) {
	const { api, filter, params, storeAs, type } = action.payload;
	let data;
	let error;
	if (type === "updating") {
		yield put({
			type: ACTIONS.UPDATE_DATA_ASPECT,
			aspect: "updating",
			payload: true,
		});
	} else {
		yield put({
			type: ACTIONS.UPDATE_DATA_ASPECT,
			aspect: "fetching",
			payload: true,
		});
	}

	try {
		data = yield fetchRoute({ route: filter, api }).then((result) => {
			if (!isEmpty(result.data)) {
				return JSON.parse(result.data);
			} else return {};
		});

		yield put({
			type: ACTIONS.UPDATE_DATA_ASPECT,
			aspect: storeAs,
			payload: data,
		});
		yield put({
			type: ACTIONS.UPDATE_DATA_ASPECT,
			aspect: "fetching",
			payload: false,
		});
	} catch (err) {
		console.log(err);
		error = e.message;
	}
}

export default function* dataSaga() {
	yield takeLatest(ACTIONS.DATA_SAGA, handleData);
}
