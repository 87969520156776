import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as ACTIONS from "../actions";

//MUI
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
//HOCS
import { withStyles } from "@material-ui/core/styles";

class Dashboard extends Component {
	constructor(props) {
		super();
		this.state = {
			buildings: props.buildings,
		};
	}

	componentDidMount() {
		if (!this.props.didFetchUser) {
			this.props.reroute("/");
		} else {
			this.props.getData(this.props.api, "buildings", null, "buildings");
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.buildings !== this.state.buildings) {
			this.setState({ buildings: this.props.buildings });
		}

		if (!this.props.firstLoad) {
			this.props.setData("firstLoad", true);
			this.props.reroute("/screen");
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.buildings !== prevState.buildings) {
			return { buildings: nextProps.buildings };
		} else return null;
	}

	render() {
		const { didFetchUser, fetching } = this.props;
		const { buildings } = this.state;

		if (!didFetchUser || fetching) {
			return <LinearProgress color="secondary" />;
		}

		console.log(this.state.buildings);

		return (
			<Container maxWidth="md">
				<div />
				{/*  */}
			</Container>
		);
	}
}

const styles = (theme) => ({
	root: {},
});

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	connect(
		(state) => ({
			api: state.user.api,
			buildings: state.data.buildings,
			didFetchUser: state.user.didFetchUser,
			fetching: state.data.fetching,
		}),
		(dispatch) => ({
			reroute: (route) => dispatch(push(route)),
			setData: (aspect, payload) =>
				dispatch({
					type: ACTIONS.UPDATE_DATA_ASPECT,
					aspect,
					payload,
				}),
			getData: (api, filter, params, storeAs) =>
				dispatch({
					type: ACTIONS.DATA_SAGA,
					payload: { api, filter, params, storeAs },
				}),
		})
	),
	withStyles(styles)
)(Dashboard);
