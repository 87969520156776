import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

const defaultConfig = {
	apiKey: "AIzaSyCE8lNrFXLCER67SVMIEY9Cr_NmE02ggmk",
	authDomain: "tapasuitesv2.firebaseapp.com",
	databaseURL: "https://tapasuitesv2.firebaseio.com",
	projectId: "tapasuitesv2",
	storageBucket: "",
	messagingSenderId: "1020467276084",
	appId: "1:1020467276084:web:a13b665bdab58171",
};

if (!firebase.apps.length) {
	firebase.initializeApp(defaultConfig);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
