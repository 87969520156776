import React from "react";

//Routes
import { Route, Switch } from "react-router";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import Landing from "../pages/Landing";
import Dashboard from "../pages/Dashboard";
import Screen from "../pages/Screen";

//SUITES
//Auth
export const LANDING = "/";
export const DASHBOARD = "/dashboard";
export const SCREEN = "/screen";

//Routes Object
const routes = (
  <div style={{ background: "white" }}>
    <Navigation />
    <Switch>
      <Route exact path={LANDING} component={Landing} />
      <Route exact path={DASHBOARD} component={Dashboard} />
      <Route exact path={SCREEN} component={Screen} />
    </Switch>
    <Footer />
  </div>
);

export default routes;
