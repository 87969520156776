import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { history } from "../store";
import { push } from "connected-react-router";
import { DASHBOARD } from "../routes";

import { USER_SAGA } from "../actions";

//HOCs
import AuthUserContext from "../hocs/context";
import withAuthentication from "../hocs/withAuthentication";

//Router
import { ConnectedRouter } from "connected-react-router";
import routes from "../routes";

//Styles
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, LinearProgress } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0063BD"
    },
    secondary: {
      main: "#F4A900"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    body1: {
      letterSpacing: ".075rem",
      userSelect: "none"
    },
    h6: {
      letterSpacing: ".15rem",
      fontVariant: "all-petite-caps"
    }
  }
});

class App extends Component {
  state = {
    authUser: this.props.authUser,
    fetchingUser: this.props.fetchingUser,
    didFetchUser: this.props.didFetchUser
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.authUser !== this.props.authUser) {
      this.setState({ authUser: this.props.authUser });
    }

    if (this.props.authUser && !prevProps.authUser) {
      this.props.getUser(this.props.authUser.uid);
    }

    if (
      prevProps.didFetchUser !== this.props.didFetchUser ||
      prevProps.fetchingUser !== this.props.fetchingUser
    ) {
      this.setState({
        didFetchUser: this.props.didFetchUser,
        fetchingUser: this.props.fetchingUser
      });
    }

    if (this.props.didFetchUser) {
      this.props.reroute(DASHBOARD);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.authUser !== prevState.authUser) {
      return { authUser: nextProps.authUser };
    } else if (nextProps.didFetchUser !== prevState.didFetchUser) {
      return {
        didFetchUser: nextProps.didFetchUser,
        fetchingUser: nextProps.fetchingUser
      };
    } else {
      return null;
    }
  }

  render() {
    const { authUser, fetchingUser } = this.state;

    if (fetchingUser) {
      return <LinearProgress />;
    }

    return (
      <AuthUserContext.Provider value={authUser}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        </MuiThemeProvider>
      </AuthUserContext.Provider>
    );
  }
}

export default compose(
  connect(
    state => ({
      authUser: state.session.authUser,
      fetchingUser: state.user.fetchingUser,
      didFetchUser: state.user.didFetchUser
    }),
    dispatch => ({
      reroute: route => dispatch(push(route)),

      getUser: uid =>
        dispatch({
          type: USER_SAGA,
          filter: "GET_USER",
          payload: { uid }
        })
    })
  ),
  withAuthentication()
)(App);
