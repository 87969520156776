// eslint-disable-next-line
import * as ACTIONS from "../actions";

const INITIAL_STATE = {
  hexBlue: "#0868BF"
};

function constantsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Generic Updates
    case ACTIONS.UPDATE_CONSTANT_ASPECT: {
      return { ...state, [`${action.aspect}`]: action.payload };
    }

    case ACTIONS.UPDATE_CONSTANT_REDUCER: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}

export default constantsReducer;
