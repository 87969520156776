import { put, takeEvery } from "redux-saga/effects";
import { functions } from "../firebase/firebase";

import { isEmpty } from "lodash";

//Actions
import * as ACTIONS from "../actions";

//Saga Handler
function* handlePost(action) {
  const { api, id, route, value } = action;
  try {
    const postData = functions.httpsCallable("postData");
    const data = yield postData({ api, route, id, value }).then(result => {
      return result;
    });

    const { data: returned = {} } = data;

    if (!isEmpty(returned)) {
      yield put({
        type: ACTIONS.UPDATE_DATA_ASPECT,
        aspect: "updatePoints",
        payload: returned
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* postSaga() {
  yield takeEvery(ACTIONS.POST_SAGA, handlePost);
}
