import React from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

function MadeWithLove() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ textAlign: "right" }}
    >
      Powered by TAPA
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  footer: {
    marginTop: "auto",
    backgroundColor: "white",
    background: props => props.hexBlue
  }
}));

const Footer = ({ hexBlue }) => {
  const classes = useStyles({ hexBlue });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="md" style={{ padding: "1rem" }}>
          <MadeWithLove />
        </Container>
      </footer>
    </div>
  );
};

export default connect(state => ({
  hexBlue: state.constants.hexBlue
}))(Footer);
