import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

//MUI
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//Grid
import { GridGenerator, HexGrid, Layout, Hexagon } from "react-hexgrid";
import * as d3 from "d3";

import { compact, isEmpty } from "lodash";

//Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Images
import HeatmapOverlay from "../static/heatmapOverlay.png";
import HeatmapOverlayTop from "../static/heatmapOverlayTop.png";
import CalendarGraphic from "./../static/calendarGraphic.png";
import CalendarGraphicSuites from "./../static/calendarGraphicSuites.png";
import { LinearProgress } from "@material-ui/core";

const calStyles = (theme) => ({
	primaryText: {
		color: "white",
		fontSize: "1.5rem",
	},
	secondaryText: {
		color: "#ccc",
		fontSize: "1.25rem",
	},
});

const CalendarPoint = (props) => {
	let { classes, data } = props;

	const fts = data.timeStart.split("-");
	const fte = data.timeEnd.split("-");

	return (
		<ListItem alignItems="flex-start" style={{ paddingLeft: "1rem" }}>
			<ListItemText
				style={{
					flexBasis: "33%",
					display: "flex",
					justifyContent: "flex-start",
					flexDirection: "column",
					alignItems: "flex-start",
				}}
				classes={{
					primary: classes.primaryText,
					secondary: classes.secondaryText,
				}}
				primary={data.name}
				secondary={data.contact ? data.contact.name : ""}
			/>
			<ListItemText
				style={{
					flexBasis: "33%",
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
				}}
				classes={{
					primary: classes.primaryText,
					secondary: classes.secondaryText,
				}}
				primary={`${fts[1]} / ${fts[2]}`}
				secondary={`${fts[3]} - ${fte[3]}`}
			/>
			<ListItemText
				style={{
					flexBasis: "33%",
					display: "flex",
					justifyContent: "flex-end",
					flexDirection: "column",
					alignItems: "flex-end",
				}}
				primary="Status"
				secondary={data.status}
				classes={{
					primary: classes.primaryText,
					secondary: classes.secondaryText,
				}}
			/>
		</ListItem>
	);
};

const StyledCalendarPoint = withStyles(calStyles)(CalendarPoint);

// Get relevant data
const filterTemp = (buildings) => {
	if (!buildings[0]) return [];

	let devices = buildings[0].devices.map((d) => {
		return d.points.filter((p) => {
			return (
				p.dataObjectType === "temperature" &&
				p.readOnly === true &&
				p.name === "Space Temp"
			);
		});
	});

	return [].concat.apply([], devices);
};

const filterCalc = (buildings) => {
	if (!buildings[0]) return [];

	let calendars = buildings[0].devices.map((d) => {
		if (
			(d.type === "hvac" || d.type === "calendar") &&
			d.points.length &&
			(d.name === "Shaw Conference Room" ||
				d.name === "Logan Conference Room" ||
				d.name === "Brookland Conference Room")
		) {
			return d;
		} else return null;
	});

	return compact(calendars);
};

// Polling
const useInterval = (callback, delay) => {
	const savedCallback = React.useRef();

	React.useEffect(() => {
		savedCallback.current = callback;
	});

	React.useEffect(() => {
		const filter = () => {
			savedCallback.current();
		};

		let id = setInterval(filter, delay);
		return () => clearInterval(id);
	}, [delay]);
};

const Screen = (props) => {
	const [calendarData, setCalendarData] = React.useState([]);
	const [tempData, setTempData] = React.useState([]);

	const { buildings, fetching, didFetchUser, classes, reroute } = props;

	// Poll
	useInterval(() => {
		filterTemp(buildings);
		filterCalc(buildings);
	}, 60000);

	//Load tempData
	React.useEffect(() => {
		if (buildings[0] && !Boolean(tempData.length)) {
			setTempData(filterTemp(buildings));
			setCalendarData(filterCalc(buildings));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buildings, tempData.length]);

	// Send back if no data
	if (!buildings) {
		reroute("/");
		return <div />;
	}

	if (fetching || !didFetchUser) {
		return <LinearProgress />;
	}

	// Hexes
	const indices = [
		714, 940, 1165, 1168, 943, 898, 718, 583, 535, 447, 126, 355, 532, 208, 121,
		116, 112, 198, 99, 108, 104, 317, 452, 457, 592, 728, 586, 863, 721, 855,
		906, 1039, 1044,
	];

	const vavMap = (temps) => {
		// IDX, Q, R, S = -Q-R
		const deviceMap = [
			[714, 32, 15],
			[940, 30, 20],
			[1165, 28, 25],
			[1168, 31, 25],
			[943, 33, 20],
			[898, 34, 19],
			[718, 36, 15],
			[583, 37, 12],
			[535, 35, 11],
			[447, 38, 9],
			[126, 35, 2],
			[355, 37, 7],
			[532, 32, 11],
			[208, 26, 4],
			[121, 30, 2],
			[116, 25, 2],
			[112, 21, 2],
			[198, 16, 4],
			[99, 8, 2],
			[108, 17, 2],
			[104, 13, 2],
			[317, -1, 7],
			[452, -3, 10],
			[457, 2, 10],
			[592, 1, 13],
			[728, 0, 16],
			[586, -5, 13],
			[863, -1, 19],
			[721, -7, 16],
			[855, -9, 19],
			[906, -4, 20],
			[1039, -7, 23],
			[1044, -2, 23],
		];

		const devices = [];

		temps.forEach((point, idx) => {
			let calc = Boolean(deviceMap[idx]);
			devices.push({
				idx: calc ? deviceMap[idx][0] : 0,
				q: calc ? deviceMap[idx][1] : 0,
				r: calc ? deviceMap[idx][2] : 0,
				s: calc ? -deviceMap[idx][1] - deviceMap[idx][2] : 0,
				val: Number(point.data.value),
			});
		});

		return devices;
	};

	const mapTempToHex = (hexas, vavMap, temps, indices) => {
		const mappedHexas = hexas.map((hex, idx) => {
			const index = indices.indexOf(idx);
			if (index !== -1) {
				hex["val"] = temps[index]?.data.value;
			} else {
				hex["val"] = 0;
			}
			return hex;
		});
		return mappedHexas;
	};

	const vavTemp = vavMap(tempData);

	const ryb = d3.scaleSequential(d3.interpolateRdYlBu);
	const linearScale = d3.scaleLinear().domain([68, 72]).range([1, 0]);

	const hexas = GridGenerator.rectangle(45, 28);
	const hexTemp = mapTempToHex(hexas, vavTemp, tempData, indices);

	const neighborMap = () => {
		const hexes = [];
		hexTemp.forEach((hex, i) => {
			if (hex.val !== 0) {
				hexes.push(
					<Hexagon
						key={i}
						q={hex.q}
						r={hex.r}
						s={hex.s}
						cellStyle={{
							fill: ryb(linearScale(hex.val)),
							fillOpacity: 0.5,
						}}
					/>
				);

				for (let i = -5; i < 5; i++) {
					for (let j = -5; j < 5; j++) {
						hexes.push(
							<Hexagon
								key={`alt_${i}${Math.random()}`}
								q={hex.q + i}
								r={hex.r + j}
								s={-hex.q + i - (hex.r + j)}
								cellStyle={{
									fill: ryb(linearScale(hex.val)),
									fillOpacity: Math.random() > 0.5 ? 0.1 : 0.15,
								}}
							/>
						);
					}
				}
			}
		});
		return hexes;
	};

	const calendar = () => {
		const calComponents = calendarData.map((c) => {
			return (
				<Grid item xs={12} key={c.name} className={classes.calendarContainer}>
					<Typography
						variant="h6"
						align="center"
						style={{ padding: "2rem", fontSize: "2rem" }}
					>
						{c.name}
					</Typography>
					{c.points.map((p) => {
						if (
							!p.data.upcoming ||
							(isEmpty(p.data.current) && !Boolean(p.data.upcoming.length))
						) {
							return null;
						}

						return (
							<List key={p.id}>
								{!isEmpty(p.data.current) && (
									<StyledCalendarPoint data={p.data.current} />
								)}
								{p.data.upcoming.map((cal, idx) => {
									return <StyledCalendarPoint data={cal} key={`idx_${idx}`} />;
								})}
							</List>
						);
					})}
				</Grid>
			);
		});

		return calComponents;
	};

	return (
		<>
			<span
				style={{
					width: "100%",
					height: "200%",
					position: "absolute",
					background: "#0868BF",
				}}
			/>
			<Paper
				style={{
					width: "1351px",
					backgroundColor: "#0868BF",
					margin: "0 auto",
				}}
			>
				<Carousel
					style={{ backgroundColor: "#0868BF" }}
					infiniteLoop
					autoPlay
					interval={8000}
					transitionTime={500}
					showIndicators={false}
					showStatus={false}
					showArrows={false}
					showThumbs={false}
				>
					<Grid
						className={classes.container}
						style={{ backgroundColor: "#0868BF" }}
					>
						<img
							className={classes.image}
							src={HeatmapOverlay}
							alt="heatmap overlay"
							width={1351}
							height="auto"
						/>

						<Grid className={classes.heatmap}>
							<HexGrid width={1325} height={700} viewBox="35 0 100 100">
								<Layout
									size={{ x: 2, y: 2 }}
									flat={false}
									spacing={1}
									origin={{ x: 0, y: 10 }}
								>
									{neighborMap()}
								</Layout>
							</HexGrid>
						</Grid>

						<img
							className={classes.image}
							src={HeatmapOverlayTop}
							alt="heatmap overlay"
							width={1351}
							height="auto"
						/>
					</Grid>
					<img src={CalendarGraphic} width={"100%"} alt={"Schedule"} />
					<img src={CalendarGraphicSuites} width={"100%"} alt={"Schedule"} />
				</Carousel>
				<Grid
					container
					style={{ marginTop: "-55px", backgroundColor: "#0868BF" }}
				>
					<Grid item xs={12}>
						<Carousel
							infiniteLoop
							autoPlay
							interval={6000}
							transitionTime={500}
							showIndicators={false}
							showStatus={false}
							showArrows={false}
							showThumbs={false}
						>
							{calendar()}
						</Carousel>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};

const styles = (theme) => ({
	container: {
		position: "relative",
		width: "100%",
		backgroundColor: "#0868BF",
	},
	image: {
		position: "absolute",
		left: 0,
	},
	heatmap: {
		position: "absolute",
		width: 1351,
		height: 715,
	},
});

export default connect(
	(state) => ({
		buildings: state.data.buildings,
		calendar: state.data.calendars,
		didFetchUser: state.user.didFetchUser,
		fetching: state.data.fetching,
	}),
	(dispatch) => ({
		reroute: (route) => dispatch(push(route)),
	})
)(withStyles(styles)(Screen));
